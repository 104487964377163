import Autoplay from 'embla-carousel-autoplay'
import * as React from 'react'

import { CardContent } from '@/components/ui/atoms/card'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/atoms/carousel'
import type { IBanner } from '@/types/banners'

export default function BannersHome({ banners }: { banners: Array<IBanner> }) {
  const plugin = React.useRef(Autoplay({ stopOnMouseEnter: true }))

  return (
    <>
      <Carousel
        plugins={[plugin.current]}
        className="w-full"
        onMouseEnter={plugin.current.stop}
        onMouseLeave={plugin.current.reset}
        opts={{
          loop: true,
        }}
      >
        <CarouselContent>
          {banners.map((banner, index) => (
            <CarouselItem className="pl-0" key={banner._id}>
              <CardContent className="flex items-center justify-center p-0">
                <div className="w-full">
                  <a
                    href={banner.link}
                    aria-label={`Banner página inicial - ${index}`}
                  >
                    <img
                      src={banner.large_image}
                      srcSet={`
                          ${banner.small_image} 425w,
                          ${banner.medium_image} 768w,
                          ${banner.large_image} 1024w
                        `}
                      sizes="(max-width: 425px) 425px, (max-width: 768px) 768px, 1024px"
                      alt={`Banner página inicial - ${index}`}
                      width="1024"
                      height="600"
                      className="object-cover w-full"
                      loading="eager"
                      decoding="async"
                    />
                  </a>
                </div>
              </CardContent>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </>
  )
}
